import * as React from 'react';
import tiktokMp4 from "../videos/youngCoach.mp4";
import tiktokWebm from "../videos/youngCoach.webm"; // Import the WebM version
import daboMp4 from "../videos/DaboSwinney.mp4"; // Import the MP4 version of Dabo Swinney video
import daboWebm from "../videos/DaboSwinney.webm"; // Import the WebM version of Dabo Swinney video
import { Carousel } from './Carousel';
import { CarouselItem } from './CarouselItem';

export function Problem() {
  return (
    <div id="problem">
      <div>
        <h4 className="subTitle problem">The Problem</h4>
      </div>
      <Carousel>
        <CarouselItem>
          <iframe
            className='responsiveVideo'
            src="https://www.youtube.com/embed/oRxRigZMwKM?si=our58IAJal9aQYRN"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </CarouselItem>
        <CarouselItem>
          <iframe
            className='responsiveVideo'
            src="https://www.espn.com/core/video/iframe/_/id/21201017/endcard/false"
            title="ESPN video player"
            allowFullScreen
            frameBorder="0"
          />
        </CarouselItem>
        <CarouselItem>
          <iframe
            className='responsiveVideo'
            src="https://www.youtube.com/embed/FT7vi91CIjk"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen
          />
        </CarouselItem>
        <CarouselItem>
          <iframe
            className='responsiveVideo'
            src="https://www.youtube.com/embed/dDlCa4IoPvE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen
          />
        </CarouselItem>
        <CarouselItem>
          <iframe
            className='responsiveVideo'
            src="https://www.youtube.com/embed/nnY501c0hPQ"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen
          />
        </CarouselItem>
        <CarouselItem>
          <div className="verticalVideo">
            <video className='responsiveVideo' controls style={{ background: "black" }}>
              <source src={tiktokMp4} type='video/mp4' />
              <source src={tiktokWebm} type='video/webm' />
              Your browser does not support the video tag.
            </video>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div>
            <video className='responsiveVideo' autoPlay loop muted>
              <source src={daboMp4} type='video/mp4' />
              <source src={daboWebm} type='video/webm' />
              Your browser does not support the video tag.
            </video>
          </div>
        </CarouselItem>
      </Carousel>
    </div>
  );
}
