import * as React from 'react';
import { HorizontalNav } from "../components/HorizontalNav";

export function Questions() {

    const questionData = {
        questions: [
            'Is there a Utility Patent on T.O.S.S.?', 
            'Is there a Design Patent on T.O.S.S.?', 
            'What sports can this device be used in?', 
            'How will this device change the game it is used in?', 
            'Will each coach be equipped with a device?', 
            'Does the device require internet access?', 
            'How fast is the speed of transmission from the coach’s device to the referee’s wristband?', 
            'Does the inventor have majority ownership of T.O.S.S.', 
            'Is the T.O.S.S. device waterproof?', 
            'Is training required to use the T.O.S.S. device.', 
            'How secure is the T.O.S.S. device from potential interference and hacking?', 
            'Have others in the industry reviewed the efficacy and value of the device?', 
            'What are the next steps in the development of T.O.S.S.?', 
            'How can investors get involved with T.O.S.S.?',
        ],
        answers: [
            'Yes. US Patent No. US 11,759,692,B2  Issued September 19, 2023', 
            'No', 
            'Several sports at every level, including basketball, football, hockey, and more.', 
            'T.O.S.S. device will expedite the speed in which a coach’s time out request is received by the officiating staff. T.O.S.S. will reduce the potential for human error and selective acknowledgement during a coach/referee communication.', 
            'Yes. Each head coach will be equipped with a transmitter device.', 
            'Yes. The device will transmit a signal over a network when the button is pressed by the respective coach. However, T.O. S. S. is not limited to any particular type of communication system, network, protocol, format, or application.', 
            'This innovative technology revolutionizes the time out process by providing an instantaneous transmission/receiver connection.', 
            'Maria Carthon/inventor owns 100% of T.O.S.S.', 
            'T.O.S.S. is water-resistant. It is humidity-protected and can endure a bit of water splashes and sweat. The crown is properly sealed to prevent water ingress. The threaded caseback connection provides a strong, reliable seal.', 
            'The coach’s device and referee device will require minimal training to operate. However, each league will determine what amount of training will be required prior to use.', 
            'Each organization will be encouraged to use firewalls, use VPN, use two-factor authentication whenever possible, and organizations will be encouraged to regularly update device’s software and firmware to ensure that the latest security patches are installed.', 
            'Yes. The device has been presented to NBA and ex-NBA players, NBA officials, NCAA college coaches, high school game officials, high school coaches, and more.', 
            'T.O.S.S. is in the design stage after which a design patent will be sought.', 
            'Contact Maria Carthon (inventor/owner) at mariacarthon@tosssystem.com or 770-634-6596',
        ],
    }

    const [showAnswer, setShowAnswer] = React.useState(null)

    const toggleVisibility = (index) => {
        setShowAnswer(showAnswer === index ? null : index);
    };

    return(
        <>
            <HorizontalNav/>
            <div className="questions-page">
                <h1 className='questions-title'>Frequently <br></br> Asked Questions</h1>
                <div className='questions-list'>
                    {questionData.questions.map((question, i) => (
                        <div key={i} className='question-container'>
                            <div className="question" onClick={() => toggleVisibility(i)}>
                                {question}
                                <button>{showAnswer === i ? '-' : '+'}</button>
                            </div>
                            {showAnswer === i && (
                                <div className="answer">
                                    {questionData.answers[i]}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}