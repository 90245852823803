import { Link } from "react-router-dom"

export function VerticalNav({ isActive, handleClick }) {

  return (
    <div id="verticalNav" className={isActive ? "active": null}>
      <div className="opacityLayer"></div>
      <div className="verticalLinks">
        <Link to="/About" onClick={handleClick}>About</Link>
        <Link to="/Questions" onClick={handleClick}>Questions</Link>
        <Link to="/Contact" onClick={handleClick}>Contact</Link>
      </div>
    </div>
  )
}
