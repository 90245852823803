import * as React from 'react';
import { HamburgerNav } from "./HamburgerNav";
import { Title } from './Title';
import landingVideoMp4 from '../videos/landingVideoCompressed.mp4';
import landingVideoWebm from '../videos/landingVideoCompressed.webm';

export function LandingScreen() {
  return (
    <div id="landingScreen">
      <video className='landingVideo' autoPlay loop muted>
        <source src={landingVideoMp4} type='video/mp4' />
        <source src={landingVideoWebm} type='video/webm' />
        Your browser does not support the video tag.
      </video>
      <Title />
      <HamburgerNav className="hamburgerNav" />
    </div>
  );
}
